<script lang="ts">
  import { fade } from "svelte/transition";
  export let url: string;
  export let text: string;

  let visible: boolean = false;
</script>

<style>
  :root {
    --scale: 0.2;
    --preview-width: 300px;
    --preview-height: 200px;
  }
  a {
    text-decoration: none;
    color: #7259ee;
    transition: color ease 0.3s;
    font-weight: bold;
    position: relative;
  }
  a:hover {
    color: #3d1fdb;
  }
  .preview {
    position: absolute;
    z-index: 100;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(202, 202, 202);
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 0;
    overflow: hidden;
    width: var(--preview-width);
    height: var(--preview-height);
  }

  #frame {
    width: calc(var(--preview-width) / var(--scale));
    height: calc(var(--preview-height) / var(--scale));
    pointer-events: none;
  }
  #frame {
    zoom: 0;
    -ms-zoom: var(--scale);

    transform: scale(var(--scale));
    -moz-transform: scale(var(--scale));
    -o-transform: scale(var(--scale));
    -webkit-transform: scale(var(--scale));

    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  span {
    padding: 0;
  }
</style>

<a
  href={url}
  on:mouseenter={() => (visible = true)}
  on:mouseleave={() => (visible = false)}>
  {#if visible}
    <div transition:fade={{ duration: 200 }} class="preview">
      <iframe scrolling="no" id="frame" title={url} src={url} />
    </div>
  {/if}
  <span> {text} </span>
</a>
